import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Serverless dev, QA, and prod",
  "description": "How do you test and share code without breaking user experience?",
  "image": "./img/dev-qa-prod.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "serverless-dev-qa-and-prod"
    }}>{`Serverless dev, QA, and prod`}</h1>
    <p><img parentName="p" {...{
        "src": "/20f0700c8050a8dd7ce0424ffe4b4a2f/dev-qa-prod.svg",
        "alt": "Dev, QA, and prod"
      }}></img></p>
    <p>{`You're building an app and want to show a friend. Do you ship to production?`}</p>
    <p>{`You're trying a new feature that doesn't do localhost. Do you publish?`}</p>
    <p>{`You've built a pipeline that edits user data and want to make sure it works. Test in production?`}</p>
    <p>{`If you're brave enough ... `}</p>
    <h2 {...{
      "id": "before-theres-users"
    }}>{`Before there's users`}</h2>
    <p>{`None of this matters until you have users. Build on the main branch, ship to production, test in real life. Enjoy yourself!`}</p>
    <p>{`Coding at this stage is `}<em parentName="p">{`fun`}</em>{`. `}</p>
    <p>{`You don't have to worry about corrupting user data. No concerns about disrupting a user's workflow. You don't even need to worry about shipping bugs!`}</p>
    <p>{`If nobody noticed the bug, was the bug even there?`}</p>
    <p>{`A word of caution: It's easy to fill your database with crappy data. Try to start production clean. `}</p>
    <p>{`Thank me later when counting users isn't a 5 step process. You'd be surprised how hard it can be to answer `}<em parentName="p">{`"How many users do we have?"`}</em>{`.`}</p>
    <h2 {...{
      "id": "localhost-vs-production"
    }}>{`Localhost vs. Production`}</h2>
    <p>{`Once you have users, you need a way to distinguish production from development. That's easy on a solo project.`}</p>
    <p>{`Localhost is for development, production is for production. Run a copy of production on your machine and test.`}</p>
    <p>{`The bigger your system, the trickier this gets. You need to host a database, run queues, caching layers, etc.`}</p>
    <div id="lock" />
    <p>{`You can get close with the `}<a parentName="p" {...{
        "href": "https://www.serverless.com/plugins/serverless-localstack"
      }}>{`LocalStack plugin for the Serverless Framework`}</a>{`. But only production is like production. ✌️`}</p>
    <p>{`Plus you can't show off localhost to a friend or coworker.`}</p>
    <h2 {...{
      "id": "the-3-stage-split"
    }}>{`The 3 stage split`}</h2>
    <p>{`A common solution to the production vs. development problem is the 3 stage split:`}</p>
    <ol {...{
      "start": 0
    }}>
      <li parentName="ol">{`localhost`}</li>
      <li parentName="ol">{`development`}</li>
      <li parentName="ol">{`staging / QA`}</li>
      <li parentName="ol">{`production`}</li>
    </ol>
    <p>{`You build and test on localhost. Get fast iteration and reasonable certainty that your code works.`}</p>
    <p>{`With the Serverless Framework, you can `}<a parentName="p" {...{
        "href": "https://www.serverless.com/framework/docs/providers/aws/cli-reference/invoke-local/"
      }}>{`run lambdas locally`}</a>{` like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`sls invoke local --function yourFunction
`}</code></pre>
    <p>{`You then push to development. A deployed environment that's like production, but changes lots. Data is irrelevant, used by everyone on the team.`}</p>
    <p>{`The development environment helps you test your code with others' work. You can show off to a friend, coworker, or product manager for early feedback.`}</p>
    <p>{`When that works, you push to staging. A more stable environment used to test code right before it ships. Features are production ready, early feedback incorporated.`}</p>
    <p>{`Staging is the playground for QA and final sign-off from product managers.`}</p>
    <p>{`Then you push to production. 🚀`}</p>
    <h3 {...{
      "id": "how-to-use-the-3-stage-split"
    }}>{`How to use the 3 stage split`}</h3>
    <p>{`Infrastructure-as-code makes the 3 stage split easy to set up. Have 3 branches of your codebase, deploy each to its own stage.`}</p>
    <p>{`With the serverless framework, you configure the deploy stage in `}<inlineCode parentName="p">{`serverless.yml`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml
service: my-service

provider:
    name: aws
    stage: dev
`}</code></pre>
    <p>{`Deploy with `}<inlineCode parentName="p">{`sls deploy`}</inlineCode>{` and that creates or updates the `}<inlineCode parentName="p">{`dev`}</inlineCode>{` stage. `}</p>
    <p>{`Stages work via name-spacing. Every resource embeds the stage as part of its name and URL. Keep that in mind when naming resources manually.`}</p>
    <p>{`Like when naming a queue:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml
resources:
  Resources:
    TimesTwoQueue:
      Type: "AWS::SQS::Queue"
      Properties:
        # include the stage variable in your name
        QueueName: "TimesTwoQueue-\${self:provider.stage}"
`}</code></pre>
    <p>{`Current stage is embedded in the string through the `}<inlineCode parentName="p">{`\${self:provider.stage}`}</inlineCode>{` variable.`}</p>
    <h3 {...{
      "id": "dynamic-stages"
    }}>{`Dynamic stages`}</h3>
    <p>{`Editing the stage in `}<inlineCode parentName="p">{`serverless.yml`}</inlineCode>{` on every deploy is annoying. Pass it in the command line instead.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`# serverless.yml

provider:
    name: aws
    # use stage option, dev by default
    stage: \${opt:stage, "dev"}
`}</code></pre>
    <p>{`Deploy with `}<inlineCode parentName="p">{`sls deploy --stage prod`}</inlineCode>{` to deploy to production. Defaults to `}<inlineCode parentName="p">{`dev`}</inlineCode>{`.`}</p>
    <p>{`Use a new stage to set up a new environment, existing stage to update. The framework figures it out for you.`}</p>
    <p>{`Make sure stage names match your `}<a parentName="p" {...{
        "href": "/handling-secrets"
      }}><inlineCode parentName="a">{`.env.X`}</inlineCode>{` configuration files`}</a>{`.`}</p>
    <h2 {...{
      "id": "deploy-previews"
    }}>{`Deploy previews`}</h2>
    <p>{`The 3 stage split starts breaking down around the 6 to 7 engineers mark. More if your projects are small, less if they're big.`}</p>
    <p>{`You start stepping on each other's toes.`}</p>
    <p>{`Alice is working on a big feature and she'll need 3 months. During that time none of her work can go to production. She'd like to test on development.`}</p>
    <p>{`Bob meanwhile is fixing bugs and keeping the lights on. He needs to merge his work into development, staging, and production every day.`}</p>
    <p>{`How can Bob and Alice work together?`}</p>
    <p>{`There's 2 solutions:`}</p>
    <ol>
      <li parentName="ol">{`Deploy previews`}</li>
      <li parentName="ol">{`Feature flags`}</li>
    </ol>
    <p>{`With infrastructure-as-code, deploy previews are the simple solution. Create a new stage for every large feature, deploy, show off, and test.`}</p>
    <p>{`You get an isolated environment with all the working bits and pieces. Automate it with GitHub Actions to create a new stage for every pull request.`}</p>
    <p>{`That's the model Netlify and Vercel promote. Every pull request is automatically deployed on a new copy of production with every update. 👌`}</p>
    <h2 {...{
      "id": "trunk-based-development"
    }}>{`Trunk-based development`}</h2>
    <p>{`A popular approach in large teams is trunk-based development.`}</p>
    <p>{`Everyone works on the main branch, deploys to production regularly, and uses feature flags to disable features before they're ready. A strong automated testing culture is critical.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.oreilly.com/library/view/software-engineering-at/9781492082781/ch01.html"
      }}>{`Google uses the Beyonce rule`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`If you liked it, you shoulda put a test on it`}</p>
    </blockquote>
    <p>{`Anyone can change any code at any time. Tests help you prevent accidents.`}</p>
    <p>{`Feature flags let you disable new features before they're ready. Your code hits production quickly which ensures it doesn't break. If you refactored something, others can use it. If you created new functionality, it's available.`}</p>
    <p>{`But you disable user-facing parts of your feature to avoid a broken experience.`}</p>
    <p>{`Implementing feature flags can be as easy as an environment variable with a bunch of IF statements, or as complex as progressive canary deploys. Those let you reveal a feature to 1% of users, then 5%, then 10, ...`}</p>
    <h2 {...{
      "id": "which-approach-should-you-pick"
    }}>{`Which approach should you pick?`}</h2>
    <p>{`The best approach depends on team size, established norms, correctness requirements, and your deployment environment.`}</p>
    <p>{`If you have a clean infrastructure-as-code approach, creating new stages is great. If you need manual setup, the 3 stage approach is best.`}</p>
    <p>{`You can even split your project into sub-projects. Isolated areas of concern that can move and deploy independently. Known as microservices.`}</p>
    <p>{`And remember, the easier your code is to fix and deploy, the less you have to worry about any of this. `}<strong parentName="p">{`Optimize for fast iteration over avoiding mistakes`}</strong>{`.`}</p>
    <p>{`For side projects I like to test in production. Live wild 🤘`}</p>
    <p>{`Next chapter, we look at how to think about serverless performance.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      